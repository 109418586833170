import { includes } from 'lodash';
import { resolveId } from '@wix/communities-blog-client-common';
import { YEAR_SLASH_MONTH_OPTIONAL_SLASH_DAY } from '@wix/communities-blog-universal/dist/src/constants/regexp';
import { encodeURIComponentIfNeeded } from './uri';

type SlugWithDate = Partial<{
  year: string;
  month: string;
  day: string;
  postSlug: string;
}>;

type CategorySlugParams = {
  categorySlug: string;
  subcategorySlug?: string;
};

const REGEXP_YEAR_MONTH_DAY = new RegExp(
  `^${YEAR_SLASH_MONTH_OPTIONAL_SLASH_DAY}/$`,
);

export const isOldSlug = (
  entity: { id?: string; slug?: string },
  slug: string,
) => !includes([resolveId(entity), entity.slug], slug);

export const isValidAdvancedSlug = ({
  year,
  month,
  day,
  postSlug,
}: SlugWithDate) => {
  if (!postSlug) {
    return false;
  }
  if (!day) {
    return REGEXP_YEAR_MONTH_DAY.test(`${year}/${month}/`);
  }
  return !isNaN(new Date(`${year}-${month}-${day}`).getTime());
};

export const resolvePostSlug = ({
  year,
  month,
  day,
  postSlug,
}: SlugWithDate = {}) => {
  if (!isValidAdvancedSlug({ year, month, day, postSlug })) {
    return postSlug;
  }
  return day
    ? `${year}/${month}/${day}/${postSlug}`
    : `${year}/${month}/${postSlug}`;
};

export const resolveCategorySlug = ({
  categorySlug,
  subcategorySlug,
}: CategorySlugParams): string => {
  const slug = subcategorySlug
    ? `${categorySlug}/${subcategorySlug}`
    : categorySlug;
  return slug ? slug.toLocaleLowerCase() : slug;
};

export const encodeURISlug = (slug: string) =>
  slug.split('/').map(encodeURIComponentIfNeeded).join('/');
