import React, { useEffect } from 'react';
import classNames from 'classnames';

import { getIsPostPageBundle } from '@wix/communities-blog-client-common';
import UpdateUrlsNotification from '../../../content-migration/components/update-urls-notification';
import { TooltipPortalProvider } from '../../context/tooltip-context';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import { getRoute } from '../../router/router-selectors';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { isInPostPreview } from '../../services/detect-route';
import { isInWix } from '../../services/is-in-wix';
import { isEditor } from '../../store/basic-params/basic-params-selectors';
import { AccessibilityListener } from '../accessibility-listener';
import AppWrapper from '../app-wrapper';
import { useSelector } from '../runtime-context';
import styles from './app.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  desktopHeader?: React.FunctionComponentElement<{
    menu?: React.FunctionComponentElement<any>;
  }>;
  main?: React.FunctionComponentElement<{ children?: React.ReactNode }>;
  menu?: React.FunctionComponentElement<any>;
  messageRoot?: React.FunctionComponentElement<any>;
  modalRoot?: React.FunctionComponentElement<any>;
  popoverRoot?: React.FunctionComponentElement<any>;
}>;

const AppDesktop: React.FC<Props> = ({
  children,
  className,
  desktopHeader,
  main,
  menu,
  messageRoot,
  modalRoot,
  popoverRoot,
}) => {
  const isEditorValue = useSelector(isEditor);
  const isPostPreview = useSelector((state) =>
    isInPostPreview(getRoute(state)),
  );
  const isPostPageBundle = useSelector(getIsPostPageBundle);
  const isResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const menuSettings = useBlogMenuSettings();

  useEffect(() => {
    if (!isInWix() || isPostPreview) {
      document.documentElement.classList.add('enable-scroll');
    }
  }, [isPostPreview]);

  return (
    <AppWrapper
      className={classNames(
        className,
        styles.app,
        'blog-background-color',
        isPostPageBundle && styles.postPageApp,
      )}
    >
      <TooltipPortalProvider>
        <AccessibilityListener />
        {messageRoot}
        {modalRoot}
        {popoverRoot}
        <div id="content-wrapper">
          {isEditorValue && <UpdateUrlsNotification />}
          {shouldShowHeader(desktopHeader) &&
            React.cloneElement(desktopHeader, { menu })}
          {main && React.cloneElement(main, { children })}
        </div>
      </TooltipPortalProvider>
    </AppWrapper>
  );

  function shouldShowHeader(
    headerElement: React.FunctionComponentElement<any> | undefined,
  ): headerElement is React.FunctionComponentElement<any> {
    const settingsEnabled = isResponsiveEditor
      ? menuSettings.showCategoryLabels
      : menuSettings.showBlogMenu;

    if (settingsEnabled && headerElement) {
      return true;
    }

    return false;
  }
};

export default AppDesktop;
